import { gsap } from 'gsap';

export default {
  name: 'SkillIcon',
  props: {
    skill: {
      type: Object,
      required: true,
      validator: (value) => {
        return value && typeof value === 'object' && 'chosenLibrary' in value;
      },
    },
    size: {
      type: Object,
      default: () => ({
        width: 'w-20',
        height: 'h-24',
        iconSize: 'text-3xl',
        textSize: 'text-xs',
      }),
    },
  },
  computed: {
    iconPath() {
      if (this.skill && this.skill.chosenLibrary === 'simpleIcons') {
        return `/images/icons/${this.skill.icons.simpleIcons}.svg`;
      }
      return null;
    },
    color() {
      return this.skill?.color || '#000000';
    },
  },
  methods: {
    openDetails() {
      this.$emit('open-details', this.skill);
    },
    onMouseEnter() {
      gsap.to(this.$refs.iconContainer, {
        duration: 0.3,
        scale: 1.05,
        ease: 'power2.out',
      });
    },
    onMouseLeave() {
      gsap.to(this.$refs.iconContainer, {
        duration: 0.3,
        scale: 1,
        ease: 'power2.in',
      });
    },
  },
};
