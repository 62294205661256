<template>
  <div
    class="fixed top-header inset-x-0 bottom-0 md:top-header md:right-0 md:left-nav bg-black bg-opacity-50 flex items-center justify-center z-[150]"
    @click.self="$emit('close-modal')"
    aria-modal="true"
    role="dialog"
  >
    <div
      ref="modalContainer"
      class="bg-card-background p-6 sm:p-5 md:p-6 rounded-lg max-w-3xl w-11/12 md:w-3/4 lg:w-1/2 max-h-[85vh] mb-[50px] overflow-y-auto relative text-left shadow-md animate-fadeInModal mx-auto"
    >
      <button
        ref="closeButton"
        class="absolute top-4 right-4 bg-transparent border-none text-2xl cursor-pointer text-primary-color hover:text-hover-color"
        @click="closeModal"
        aria-label="Fermer la modale"
      >
        &times;
      </button>

      <!-- Titre principal du projet -->
      <h2 class="text-2xl sm:text-3xl md:text-4xl text-secondary-color font-bold mb-4">
        {{ project.projectName }}
      </h2>

      <!-- Description détaillée -->
      <p v-if="project.detailedDescription" class="text-sm sm:text-base md:text-lg text-text-color mb-4">
        {{ project.detailedDescription }}
      </p>

      <!-- Durée du projet -->
      <p class="text-xs sm:text-sm md:text-base text-text-color mb-6">
        <strong>Durée :</strong>
        {{ formatDuration(project.date_debut, project.date_fin) }}
      </p>

      <!-- Réalisations par catégorie -->
      <div
        v-for="realization in project.realizations"
        :key="realization.category"
        class="mb-6"
      >
        <!-- Titre de la catégorie -->
        <h4 class="text-lg sm:text-xl md:text-2xl text-primary-color font-semibold mb-2">
          {{ realization.category }}
        </h4>

        <ul class="list-disc ml-5">
          <li
            v-for="item in realization.items"
            :key="item.description"
            class="mb-4"
          >
            <p class="text-sm sm:text-base md:text-lg text-text-color mb-2">{{ item.description }}</p>
            <div class="flex flex-wrap gap-4">
              <SkillIcon
                v-for="competence in item.competences"
                :key="competence.id"
                :skill="getSkill(competence)"
                class="skill-icon"
                :size="{ width: 'w-16', height: 'h-20', iconSize: 'text-2xl', textSize: 'text-xs' }"
                @open-details="openSkillDetails"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Affichage de SkillDetailModal -->
    <SkillDetailModal
      v-if="selectedSkill"
      :skill="selectedSkill"
      @close-modal="closeSkillDetails"
    />
  </div>
</template>

<script src="./ProjectModal.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
