// src/components/AboutSection/AboutSection.js
import aboutData from '@/data/about.json';
import GoalItem from './GoalItem.vue';
import ValueItem from './ValueItem.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'AboutSection',
  components: {
    GoalItem,
    ValueItem,
  },
  data() {
    return {
      about: aboutData.about,
    };
  },
  mounted() {
    // Créer une timeline GSAP pour les animations séquentielles
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$refs.aboutSection,
        start: 'top 80%',
      },
    });

    tl.from(this.$refs.aboutImage, {
      duration: 1,
      opacity: 0,
      scale: 0.8,
      ease: 'power2.out',
    })
      .from(this.$refs.aboutTitle, {
        duration: 1,
        y: -50, // Animation descendante
        opacity: 0,
        ease: 'power2.out',
      }, '-=0.8')
      .from(this.$refs.aboutDescription, {
        duration: 1,
        y: -50, // Changement de y: 50 à y: -50 pour descendre depuis le haut
        opacity: 0,
        ease: 'power2.out',
      }, '-=0.8');

    // Animation pour les paragraphes avec ScrollTrigger
    gsap.from(this.$refs.aboutParagraphs.children, {
      scrollTrigger: {
        trigger: this.$refs.aboutParagraphs,
        start: 'top 80%',
      },
      duration: 1,
      y: -30, // Animation descendante
      opacity: 0,
      stagger: 0.2,
      ease: 'power2.out',
    });

    // Animation pour les objectifs avec ScrollTrigger
    gsap.from(this.$refs.goalsSection, {
      scrollTrigger: {
        trigger: this.$refs.goalsSection,
        start: 'top 80%',
      },
      duration: 1,
      y: -100, // Changement de x: -100 à y: -100 pour descendre depuis le haut
      opacity: 0,
      ease: 'power2.out',
    });

    // Animation pour les valeurs avec ScrollTrigger
    gsap.from(this.$refs.valuesSection, {
      scrollTrigger: {
        trigger: this.$refs.valuesSection,
        start: 'top 80%',
      },
      duration: 1,
      y: -100, // Changement de x: 100 à y: -100 pour descendre depuis le haut
      opacity: 0,
      ease: 'power2.out',
    });
  },
};
