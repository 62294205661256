<!-- src/components/AboutSection/GoalItem.vue -->
<template>
  <div ref="goalItem" class="flex items-center mb-4 relative w-full">
    <i class="fas fa-check-circle text-green-600 mr-2 text-xl"></i>
    <p class="text-gray-700">{{ goal }}</p>
 
  </div>
</template>

<script>
// src/components/AboutSection/GoalItem.vue
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'GoalItem',
  props: {
    goal: {
      type: String,
      required: true,
    },
  },
  mounted() {
    gsap.from(this.$refs.goalItem, {
      scrollTrigger: {
        trigger: this.$refs.goalItem,
        start: 'top 80%',
      },
      duration: 0.8,
      y: -50, // Animation descendante
      opacity: 0,
      ease: 'power2.out',
    });
  },
};

</script>

<style scoped>
/* Aucun style personnalisé nécessaire, Tailwind s'en charge */
</style>
