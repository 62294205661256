// src/components/AccueilSection/AccueilSection.js
import homeData from '@/data/home.json';
import projectsData from '@/data/projectsData.json';
import ProjectCard from '@/components/ProjectCard/ProjectCard.vue';
import ProjectModal from '@/components/ProjectModal/ProjectModal.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'AccueilSection',
  components: {
    ProjectCard,
    ProjectModal,
  },
  data() {
    return {
      home: homeData.home,
      projects: projectsData.projects,
      selectedProject: null,
    };
  },
  computed: {
    featuredProjects() {
      return this.home.highlight.projects
        .map((projectHighlight) => this.projects.find((project) => project.id === projectHighlight.id))
        .filter((project) => project !== undefined);
    },
  },
  methods: {
    openModal(project) {
      this.selectedProject = project;
    },
    closeModal() {
      this.selectedProject = null;
    },
  },
  mounted() {
    // Animation pour la section d'accueil
    gsap.from(this.$refs.accueilSection, {
      opacity: 0,
      duration: 1,
      ease: 'power2.out',
    });

    // Créer une timeline GSAP pour les animations séquentielles
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$refs.accueilSection,
        start: 'top 80%',
      },
    });

    tl.from(this.$refs.introLogo, {
      duration: 1,
      opacity: 0,
      scale: 0.8,
      ease: 'power2.out',
    })
      .from(this.$refs.introTitle, {
        duration: 1,
        y: -50, // Animation descendante
        opacity: 0,
        ease: 'power2.out',
      }, '-=0.8')
      .from(this.$refs.introDescription, {
        duration: 1,
        y: -50, // Animation descendante
        opacity: 0,
        ease: 'power2.out',
      }, '-=0.8');

    // Animation pour les projets en vedette
    gsap.from(this.$refs.featuredProjects, {
      scrollTrigger: {
        trigger: this.$refs.featuredProjects,
        start: 'top 80%',
      },
      duration: 1,
      y: -100, // Animation descendante
      opacity: 0,
      ease: 'power2.out',
    });

    // Animation pour les cartes de projets en vedette avec stagger
    gsap.from(this.$refs.featuredProjects.querySelectorAll('.project-card'), {
      scrollTrigger: {
        trigger: this.$refs.featuredProjects,
        start: 'top 80%',
      },
      duration: 0.8,
      y: 50, // Animation ascendante
      opacity: 0,
      stagger: 0.2,
      ease: 'power2.out',
      onComplete: () => {
        gsap.set(this.$refs.featuredProjects.querySelectorAll('.project-card'), { clearProps: 'transform, opacity' });
      },
    });

    // Animation pour les sections principales
    gsap.from(this.$refs.mainSections.querySelectorAll('.main-section'), {
      scrollTrigger: {
        trigger: this.$refs.mainSections,
        start: 'top 80%',
      },
      duration: 1,
      y: -50, // Animation descendante
      opacity: 0,
      stagger: 0.2,
      ease: 'power2.out',
      onComplete: () => {
        gsap.set(this.$refs.mainSections.querySelectorAll('.main-section'), { clearProps: 'transform, opacity' });
      },
    });
  },
  beforeUnmount() { // Utilisez beforeUnmount si vous êtes sous Vue 3
    ScrollTrigger.killAll();
  },
};
