// src/components/ProjectModal/ProjectModal.js
import SkillIcon from '@/components/SkillIcon/SkillIcon.vue';
import SkillDetailModal from '@/components/SkillDetailModal/SkillDetailModal.vue';
import skillsData from '@/data/skillsData.json';
import { gsap } from 'gsap';

export default {
  name: 'ProjectModal',
  components: {
    SkillIcon,
    SkillDetailModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      skillsData,
      selectedSkill: null,
    };
  },
  mounted() {
    // Animer l'apparition du modal
    gsap.from(this.$refs.modalContainer, {
      duration: 0.5,
      opacity: 0,
      y: -50,
      ease: 'power2.out',
    });
  },
  methods: {
    closeModal() {
      // Animer la disparition du modal
      gsap.to(this.$refs.modalContainer, {
        duration: 0.5,
        opacity: 0,
        y: -50,
        ease: 'power2.in',
        onComplete: () => {
          this.$emit('close-modal');
        },
      });
    },
    getSkill(competence) {
      const { category, id } = competence;
      const skillCategory = this.skillsData[category];
      if (!skillCategory) {
        console.warn(`Catégorie "${category}" non trouvée dans skillsData.json.`);
        return null;
      }
      const skill = skillCategory.find((s) => s.id === id);
      if (!skill) {
        console.warn(
          `Compétence avec l'identifiant "${id}" dans la catégorie "${category}" non trouvée.`
        );
        return null;
      }
      return skill;
    },
    openSkillDetails(skill) {
      this.selectedSkill = skill;
    },
    closeSkillDetails() {
      this.selectedSkill = null;
    },
    formatDuration(startDate, endDate) {
      return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    },
  },
};
