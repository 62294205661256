<!-- src/components/SkillsOverview/SkillsOverview.vue -->
<template>
  <section
    id="skills"
    class="py-32 px-5 bg-background text-secondary-color min-h-screen flex flex-col relative"
  >
    <h2 class="text-4xl font-bold text-center text-primary-color mb-10">
      Compétences
      <span class="block w-16 h-1 bg-primary-color mx-auto mt-2 rounded"></span>
    </h2>

    <div
      v-for="category in skillCategories"
      :key="category.title"
      class="category mb-16"
    >
    <h3 class="text-2xl font-semibold text-primary-color mb-6 flex justify-center md:justify-start">
  {{ category.title }}
</h3>

      <!-- Alignement responsif des compétences -->
      <div class="flex flex-wrap gap-6 justify-center md:justify-start">
        <SkillIcon
          v-for="skill in filteredSkills(category.skills)"
          :key="skill.id"
          :skill="skill"
          class="skill-icon"
          :size="{ width: 'w-32', height: 'h-36', iconSize: 'text-5xl', textSize: 'text-base' }"
          @open-details="openSkillDetails"
        />
      </div>
    </div>

    <!-- Modale pour les détails de la compétence -->
    <SkillDetailModal
      v-if="selectedSkill"
      :skill="selectedSkill"
      @close-modal="closeSkillDetails"
    />
  </section>
</template>

<script src="./SkillsOverview.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire car Tailwind gère tout */
</style>
