<!-- src/components/AboutSection/AboutSection.vue -->
<template>
  <section
    id="about"
    ref="aboutSection"
    class="py-32 px-5 bg-background text-secondary min-h-screen relative text-center"
  >
    <!-- Image ou Illustration -->
    <div class="about-image mb-8">
      <img 
        ref="aboutImage"
        src="https://storage.googleapis.com/oumarbenlol-storage/images/icons/profile.webp" 
        alt="Photo de Oumar Ben Lol" 
        class="mx-auto w-48 h-48 rounded-full border-4 border-primary shadow-md transform transition-transform duration-300 hover:scale-105"
      />
    </div>

    <!-- Titre et Description -->
    <div class="about-content mb-10">
      <h2 ref="aboutTitle" class="text-4xl mb-5 text-primary">{{ about.title }}</h2>
      <p ref="aboutDescription" class="text-lg max-w-2xl mx-auto mb-10 leading-relaxed text-text">
        {{ about.description }}
      </p>
    </div>

    <!-- Paragraphes -->
    <div ref="aboutParagraphs" class="about-paragraphs mb-15 max-w-2xl mx-auto text-center">
      <p v-for="(paragraph, index) in about.paragraphs" :key="index" class="text-base leading-7 mb-5">
        {{ paragraph }}
      </p>
    </div>

    <!-- Mes Objectifs -->
    <div ref="goalsSection" class="goals-section mb-15 max-w-2xl mx-auto text-left">
      <h3 class="text-2xl mb-8 text-primary text-center">{{ about.goals.title }}</h3>
      <div class="goals-list flex flex-col items-start w-full">
        <GoalItem
          v-for="(goal, index) in about.goals.items"
          :key="index"
          :goal="goal"
        />
      </div>
    </div>

    <!-- Mes Valeurs -->
    <div ref="valuesSection" class="values-section mb-15 max-w-2xl mx-auto text-left">
      <h3 class="text-2xl mb-8 text-primary text-center">{{ about.values.title }}</h3>
      <div class="values-list flex flex-col items-start w-full">
        <ValueItem
          v-for="(value, index) in about.values.items"
          :key="index"
          :value="value"
        />
      </div>
    </div>
  </section>
</template>

<script src="./AboutSection.js"></script>

<style scoped>
/* Supprimer les classes d'animation CSS précédentes */
</style>
