// src/components/WorkExperience/WorkExperience.js

import experienceData from '@/data/experience.json'; // Assurez-vous que le chemin est correct

export default {
  name: 'WorkExperience',
  data() {
    return {
      experience: experienceData.experience,
    };
  },
};
