<template>
  <div
    class="fixed top-header inset-x-0 bottom-0 md:top-header md:right-0 md:left-nav bg-black bg-opacity-50 flex items-center justify-center z-[150]"
    @click.self="$emit('close-modal')"
    aria-modal="true"
    role="dialog"
  >
    <div
      class="bg-card-background p-6 sm:p-5 md:p-6 rounded-lg max-w-3xl w-11/12 md:w-3/4 lg:w-1/2 max-h-[85vh] mb-[50px] overflow-y-auto relative text-left shadow-md animate-fadeInModal mx-auto"
    >
      <button
        @click="$emit('close-modal')"
        class="absolute top-4 right-4 bg-transparent border-none text-2xl cursor-pointer text-primary hover:text-hover"
        aria-label="Fermer la modale"
      >
        &times;
      </button>

      <div class="flex flex-col items-center">
        <img
          v-if="item.formationImageUrl && !isImageFailed(item.formationImageUrl)"
          :src="item.formationImageUrl"
          :alt="item.alt + ' - Formation'"
          class="w-full h-auto max-h-64 object-contain mb-4 rounded-lg"
          @error="handleImageError(item.formationImageUrl)"
        />

        <h3 class="text-xl sm:text-2xl md:text-3xl font-bold text-secondary mb-2">
          {{ item.title || item.degree }}
        </h3>

        <p v-if="item.year" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Année :</strong> {{ item.year }}
        </p>

        <p v-if="item.institution" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Institution :</strong> {{ item.institution }}
        </p>

        <p v-if="item.specialization" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Spécialisation :</strong> {{ item.specialization }}
        </p>

        <p v-if="item.period" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Période :</strong> {{ item.period }}
        </p>

        <p v-if="item.duration" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Durée :</strong> {{ item.duration }}
        </p>

        <p v-if="item.rhythm" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Rythme :</strong> {{ item.rhythm }}
        </p>

        <p v-if="item.distinction" class="text-sm sm:text-base md:text-lg text-text mb-2">
          <strong>Distinction :</strong> {{ item.distinction }}
        </p>

        <a
          v-if="item.link"
          :href="item.link"
          target="_blank"
          rel="noopener noreferrer"
          class="text-sm sm:text-base md:text-lg text-primary hover:text-hover transition-colors mb-4"
        >
          Voir le certificat
        </a>
      </div>

      <!-- Affichage des modules -->
      <div class="mt-4">
        <div v-if="item.categories">
          <!-- Pour les formations -->
          <div
            v-for="(category, idx) in item.categories"
            :key="idx"
            class="mb-6"
          >
            <h4 class="text-lg sm:text-xl md:text-2xl text-primary mb-2">
              {{ category.categoryName }}
            </h4>
            <ul class="list-disc ml-5">
              <li
                v-for="(module, mIdx) in category.modules"
                :key="mIdx"
                class="mb-4"
              >
                <p
                  @click="toggleModuleDetails(module)"
                  class="text-sm sm:text-base md:text-lg text-secondary cursor-pointer hover:text-primary transition-colors flex items-center"
                >
                  {{ module.name }}
                  <svg
                    :class="{
                      'transform rotate-90': module.showDetails,
                    }"
                    class="w-4 h-4 ml-2 transition-transform"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </p>
                <p
                  v-if="module.showDetails"
                  class="text-sm sm:text-base md:text-lg text-text mt-1 ml-4"
                >
                  {{ module.details }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div v-else-if="item.modules">
          <!-- Pour les certificats -->
          <h4 class="text-lg sm:text-xl md:text-2xl text-primary mb-2">Modules</h4>
          <ul class="list-disc ml-5">
            <li
              v-for="(module, idx) in item.modules"
              :key="idx"
              class="mb-4"
            >
              <p
                @click="toggleModuleDetails(module)"
                class="text-sm sm:text-base md:text-lg text-secondary cursor-pointer hover:text-primary transition-colors flex items-center"
              >
                {{ module.name }}
                <svg
                  :class="{
                    'transform rotate-90': module.showDetails,
                  }"
                  class="w-4 h-4 ml-2 transition-transform"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </p>
              <p
                v-if="module.showDetails"
                class="text-sm sm:text-base md:text-lg text-text mt-1 ml-4"
              >
                {{ module.details }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./EducationModal.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
