<template>
  <section
    id="education"
    class="pt-32 px-5 bg-background text-secondary min-h-screen relative animate-fadeIn z-[100]"
  >
    <!-- Titre principal -->
    <h2
      class="text-3xl font-bold text-primary text-center mb-8 relative after:block after:w-16 after:h-1 after:bg-primary after:mt-2 after:mx-auto after:rounded"
    >
      Parcours Académique
    </h2>

    <!-- Liste des formations -->
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
      <div
        v-for="(item, index) in education"
        :key="index"
        class="bg-card-background rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1 p-5 flex flex-col"
      >
        <div class="flex flex-col items-center mb-4">
          <img
            v-if="item.institutionImageUrl && !isImageFailed(item.institutionImageUrl)"
            :src="item.institutionImageUrl"
            :alt="item.alt + ' - Institution'"
            class="w-24 h-24 rounded-full mb-4 object-cover"
            @error="handleImageError(item.institutionImageUrl)"
            loading="lazy"
          />
          <div class="text-center">
            <h3 class="text-xl font-semibold text-secondary mb-2">
              {{ item.degree }}
            </h3>
            <p class="text-base text-text">
              <strong>Année :</strong> {{ item.year }}
            </p>
            <p class="text-base text-text">
              <strong>Établissement :</strong> {{ item.institution }}
            </p>
          </div>
        </div>
        <button
          @click="openModal(item, 'education')"
          class="mt-auto px-4 py-2 bg-primary text-white rounded-md hover:bg-hover transition-colors"
        >
          Voir les détails
        </button>
      </div>
    </div>

    <!-- Titre des certificats -->
    <h2
      class="text-3xl font-bold text-primary text-center mt-16 mb-8 relative after:block after:w-16 after:h-1 after:bg-primary after:mt-2 after:mx-auto after:rounded"
    >
      Certificats
    </h2>

    <!-- Liste des certificats -->
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
      <div
        v-for="(certificate, index) in certificates"
        :key="index"
        class="bg-card-background rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1 p-5 flex flex-col"
      >
        <div class="flex flex-col items-center mb-4">
          <img
            v-if="certificate.institutionImageUrl && !isImageFailed(certificate.institutionImageUrl)"
            :src="certificate.institutionImageUrl"
            :alt="certificate.alt + ' - Institution'"
            class="w-24 h-24 rounded-full mb-4 object-cover"
            @error="handleImageError(certificate.institutionImageUrl)"
            loading="lazy"
          />
          <div class="text-center">
            <h3 class="text-xl font-semibold text-secondary mb-2">
              {{ certificate.title }}
            </h3>
            <p class="text-base text-text">
              <strong>Institution :</strong> {{ certificate.institution }}
            </p>
          </div>
        </div>
        <button
          @click="openModal(certificate, 'certificate')"
          class="mt-auto px-4 py-2 bg-primary text-white rounded-md hover:bg-hover transition-colors"
        >
          Voir les détails
        </button>
      </div>
    </div>

    <!-- Inclusion du composant EducationModal avec transition GSAP -->
    <transition @enter="onEnter" @leave="onLeave">
      <EducationModal
        v-if="selectedItem || selectedCertificate"
        :item="selectedItem || selectedCertificate"
        :type="modalType"
        @close-modal="closeModal"
      />
    </transition>
  </section>
</template>

<script src="./EducationHistory.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
