<!-- src/components/ProjectsOverview/ProjectsOverview.vue -->
<template>
  <section
    id="projects-overview"
    class="relative min-h-screen pt-32 pb-10 px-5 bg-background text-secondary-color "
  >
    <h2
      class="text-4xl mb-10 text-center text-primary-color font-bold relative"
    >
      Projets
      <span
        class="block w-16 h-1 bg-primary-color mx-auto mt-2 rounded"
      ></span>
    </h2>

    <div
      class="grid gap-8 sm:gap-6 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <ProjectCard
        v-for="project in projects"
        :key="project.id"
        :project="project"
        :showDetail="false"
        class="project-card"
        @open-modal="openModal"
      />
    </div>

    <!-- Fenêtre modale pour afficher les détails du projet -->
    <ProjectModal
      v-if="selectedProject"
      :project="selectedProject"
      @close-modal="closeModal"
    />
  </section>
</template>

<script src="./ProjectsOverview.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
