// src/components/BlogDetails/BlogDetails.js

import PDFObject from 'pdfobject'; // Assurez-vous que pdfobject est installé via npm

export default {
  name: 'BlogDetails',
  props: {
    blog: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.blog && this.blog.pdfPath) {
      PDFObject.embed(this.blog.pdfPath, this.$refs.pdfContainer, {
        width: '100%',
        height: '100%', // Utilise toute la hauteur disponible
      });
    }
  },
  methods: {
    formatDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateStr).toLocaleDateString('fr-FR', options);
    },
  },
};
