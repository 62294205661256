<!-- src/components/WorkExperience/WorkExperience.vue -->
<template>
  <section
    id="experience"
    class="pt-32 px-5 bg-background text-secondary min-h-screen relative"
  >
    <h2
      class="text-3xl font-bold text-primary text-center mb-12 relative after:block after:w-16 after:h-1 after:bg-primary after:mt-2 after:mx-auto after:rounded"
    >
      Expérience
    </h2>
    <div class="max-w-4xl mx-auto">
      <div class="space-y-8">
        <div
          v-for="(job, index) in experience"
          :key="index"
          class="bg-card-background rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden"
        >
          <div class="md:flex">
            <div class="md:w-1/3 bg-white p-6 flex items-center justify-center">
              <img 
                :src="job.companyLogoUrl" 
                :alt="job.company + ' logo'"
                class="max-w-full max-h-24 object-contain"
              >
            </div>
            <div class="md:w-2/3 p-6">
              <h3 class="text-2xl font-semibold text-primary mb-2">
                {{ job.title }}
              </h3>
              <p class="text-lg font-medium text-secondary mb-2">
                {{ job.company }}
              </p>
              <p class="text-sm text-text mb-2">
                {{ job.startDate }} - {{ job.endDate }}
              </p>
              <p class="text-sm text-text mb-4">
                {{ job.contractType }}
              </p>
              <p class="text-base text-text">
                {{ job.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script src="./WorkExperience.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>