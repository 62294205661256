<!-- src/components/BlogList/BlogList.vue -->
<template>
  <section
    id="blog-list"
    class="pt-16 sm:pt-20 md:pt-24 px-4 sm:px-6 lg:px-8 bg-background text-secondary min-h-screen relative animate-fadeIn"
  >
    <!-- Titre principal -->
    <h2
      class="text-2xl sm:text-3xl md:text-4xl font-bold text-primary text-center mb-8 relative after:block after:w-12 sm:after:w-16 after:h-1 after:bg-primary after:mt-2 after:mx-auto after:rounded"
    >
      Nos Articles de Blog
    </h2>

    <!-- Liste des articles de blog -->
    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8 justify-items-center"
    >
      <div
        v-for="blog in blogs"
        :key="blog.id"
        class="bg-card-background w-full max-w-sm rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-2 flex flex-col animate-fadeInCard"
      >
        <!-- Image de l'article -->
        <div class="overflow-hidden">
          <img
            :src="blog.image_url"
            :alt="blog.title"
            class="w-full h-40 sm:h-48 md:h-52 lg:h-56 object-cover transition-transform duration-300 ease-in-out hover:scale-105 cursor-pointer"
            loading="lazy"
            @click="openModal(blog)"
          />
        </div>

        <!-- Contenu de la carte -->
        <div class="p-4 sm:p-5 md:p-6 flex flex-col flex-grow">
          <!-- Titre de l'article -->
          <h3
            class="text-lg sm:text-xl md:text-2xl font-semibold text-secondary-color hover:text-primary transition-colors mb-2 cursor-pointer"
            @click="openModal(blog)"
          >
            {{ blog.title }}
          </h3>

          <!-- Date de publication -->
          <p class="text-sm sm:text-base md:text-lg text-text-color mb-4">
            <strong>Date :</strong> {{ formatDate(blog.date) }}
          </p>

          <!-- Bouton pour ouvrir le modal -->
          <button
            @click="openModal(blog)"
            class="inline-block mt-auto px-4 py-2 bg-primary text-white rounded-md font-bold hover:bg-hover hover:text-white transition-colors duration-300 ease-in-out text-sm sm:text-base md:text-lg"
          >
            Lire l'article
          </button>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      v-if="isModalOpen"
      class="fixed top-[80px] left-0 right-0 bottom-[60px] bg-black bg-opacity-50 flex items-center justify-center z-[2000] md:left-[200px] md:bottom-0 animate-fadeInModal"
      @click.self="closeModal"
    >
      <div class="bg-white rounded-lg overflow-hidden w-full h-full relative p-4 md:p-8 shadow-lg transform transition-transform duration-300">
        <!-- Bouton de fermeture -->
        <button
          @click="closeModal"
          class="absolute top-2 right-2 z-[200000] bg-accent text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg transform transition-transform duration-200 hover:scale-110 hover:bg-hover focus:outline-none focus:ring-2 focus:ring-accent animate-fadeIn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <!-- Composant BlogDetails -->
        <BlogDetails :blog="selectedBlog" />
      </div>
    </div>
  </section>
</template>

<script>
import componentOptions from './BlogList.js'; // Importation du fichier JavaScript

export default componentOptions;
</script>

<style scoped>
/* Aucun style supplémentaire nécessaire */

/* Ajustement pour les petits écrans */
@media (max-width: 767px) {
  .pdf-container {
    height: 300px; /* Hauteur fixe pour les petits écrans */
  }
}
</style>
