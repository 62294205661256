<template>
  <section id="home" ref="accueilSection"
    class="pt-20 px-5 bg-background text-secondary-color min-h-[calc(100vh-80px)] relative">
    <div class="container mx-auto flex flex-col items-center">

      <!-- Logo ou Illustration -->
      <div class="logo-image mb-16 text-center">
        <img ref="introLogo" src="/favicon.webp" alt="Logo de Oumar Ben Lol"
          class="mx-auto w-24 h-24 sm:w-32 sm:h-32 rounded-full border-2 border-primary shadow-md transform transition-transform duration-300 hover:scale-105" />
      </div>

      <!-- Introduction -->
      <div class="text-center mb-16">
        <h1 ref="introTitle" class="text-3xl sm:text-4xl md:text-5xl font-bold text-primary-color mb-5">
          {{ home.title }}
        </h1>
        <p ref="introDescription" class="text-lg sm:text-xl max-w-2xl mx-auto leading-relaxed text-text mb-10">
          {{ home.introduction }}
        </p>
      </div>

      <!-- Projets en Vedette -->
      <div ref="featuredProjects" class="mb-16 w-full max-w-6xl">
        <h2 class="text-2xl sm:text-3xl md:text-4xl text-center font-bold text-primary-color mb-5">
          {{ home.highlight.title }}
        </h2>
        <p class="text-base sm:text-lg text-center mb-10 text-text">
          {{ home.highlight.description }}
        </p>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <!-- Ajout de la classe pour l'animation -->
          <ProjectCard 
            v-for="project in featuredProjects" 
            :key="project.id" 
            :project="project" 
            :showDetail="false"
            :showDuree="false" 
            @open-modal="openModal" 
            class="project-card relative" 
          ></ProjectCard>
        </div>
      </div>

      <!-- Sections Principales -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-6xl" ref="mainSections">
        <div
          class="main-section bg-card-background border border-gray-300 rounded-lg p-6 text-center transition-transform transform hover:-translate-y-1 shadow-md hover:shadow-lg flex flex-col h-full justify-between relative"
          v-for="section in home.sections" :key="section.title">

          <div class="flex-grow">
            <h3 class="text-xl sm:text-2xl md:text-2xl font-semibold text-primary-color mb-2 break-words">
              {{ section.title }}
            </h3>
            <p class="text-base sm:text-lg md:text-lg mb-4 text-text break-words whitespace-normal">
              {{ section.description }}
            </p>
          </div>

          <router-link 
  :to="section.route"
  class="inline-block mt-4 px-4 py-2 bg-primary text-white rounded-md transition-colors duration-300 hover:bg-hover hover:text-white"
>
  {{ section.linkText }}
</router-link>


        </div>
      </div>

      <!-- Modale du Projet -->
      <ProjectModal v-if="selectedProject" :project="selectedProject" @close-modal="closeModal" />
    </div>
  </section>
</template>

<script src="./AccueilSection.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
