// src/components/ProjectCard/ProjectCard.js
import { gsap } from 'gsap';

export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true,
    },
    showDetail: {
      type: Boolean,
      default: true,
    },
    showDuree: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit('open-modal', this.project);
    },
    onMouseEnter() {
      gsap.to(this.$refs.cardImage, {
        duration: 0.5,
        scale: 1.05,
        ease: 'power2.out',
      });
    },
    onMouseLeave() {
      gsap.to(this.$refs.cardImage, {
        duration: 0.5,
        scale: 1,
        ease: 'power2.in',
      });
    },
    formatDuration(startDate, endDate) {
      return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    },
  },
};
