// src/components/SkillsOverview/SkillsOverview.js
import SkillIcon from '@/components/SkillIcon/SkillIcon.vue';
import SkillDetailModal from '@/components/SkillDetailModal/SkillDetailModal.vue';
import skillsData from '@/data/skillsData.json';
import { gsap } from 'gsap';

export default {
  name: 'SkillsOverview',
  components: {
    SkillIcon,
    SkillDetailModal,
  },
  data() {
    return {
      skills: skillsData,
      selectedSkill: null,
    };
  },
  computed: {
    skillCategories() {
      return [
        { title: 'Intelligence Artificielle', skills: this.skills.artificialIntelligence },
        { title: 'Science des Données', skills: this.skills.dataScience },
        { title: 'Développement Web - Front-End', skills: this.skills['web-development-front-end'] },
        { title: 'Développement Web - Back-End', skills: this.skills['web-development-back-end'] },        { title: 'Développement Mobile', skills: this.skills.mobileDevelopment },
        { title: 'Systèmes Embarqués', skills: this.skills.embeddedSystems },
        { title: 'Autres Langages Informatiques', skills: this.skills.programmingLanguages },
        { title: 'Cloud Computing', skills: this.skills.cloudComputing },
        { title: 'DevOps & MLOps', skills: this.skills.devOpsMLOps },
        { title: 'Outils de Collaboration', skills: this.skills.collaborationTools },
        { title: 'Outils Logiciels', skills: this.skills.softwareTools },
      ];
    },
  },
  methods: {
    openSkillDetails(skill) {
      this.selectedSkill = skill;
    },
    closeSkillDetails() {
      this.selectedSkill = null;
    },
    filteredSkills(skills) {
      return skills.filter((skill) => skill.isDisplayed);
    },
  },
  mounted() {
    // Animer les icônes de compétences lors du montage du composant
    this.$nextTick(() => {
      gsap.from('.skill-icon', {
        duration: 0.5,
        opacity: 0,
        y: 30,
        stagger: 0.05,
        ease: 'power2.out',
      });
    });
  },
};
