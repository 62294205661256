// src/components/BlogList/BlogList.js

import BlogDetails from '../BlogDetails/BlogDetails.vue'; // Chemin correct vers BlogDetails.vue
import blogData from '@/data/blog.json'; // Importation statique du fichier JSON

export default {
  name: 'BlogList',
  components: {
    BlogDetails, // Enregistrement du composant BlogDetails
  },
  data() {
    return {
      blogs: blogData.blogs, // Utilisation des données importées
      selectedBlog: null, // Pour stocker le blog sélectionné
      isModalOpen: false, // Pour contrôler la visibilité du modal
    };
  },
  methods: {
    formatDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateStr).toLocaleDateString('fr-FR', options);
    },
    openModal(blog) {
      this.selectedBlog = blog;
      this.isModalOpen = true;
      document.body.style.overflow = 'hidden'; // Empêche le défilement de fond
      document.addEventListener('keydown', this.handleEsc);
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedBlog = null;
      document.body.style.overflow = 'auto'; // Rétablit le défilement de fond
      document.removeEventListener('keydown', this.handleEsc);
    },
    handleEsc(event) {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    },
  },
};
