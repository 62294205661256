<template>
  <div
    v-if="skill"
    ref="iconContainer"
    :class="[
      'bg-card-background p-2 rounded-lg shadow-md cursor-pointer flex flex-col items-center justify-center skill-icon transition-transform duration-300',
      size.width,
      size.height,
    ]"
    @click="openDetails"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    tabindex="0"
    @keypress.enter="openDetails"
    role="button"
    aria-pressed="false"
  >
    <!-- Affichage de l'icône -->
    <template v-if="skill.chosenLibrary === 'simpleIcons'">
      <img
        :src="iconPath"
        :alt="skill.title"
        class="mb-1"
        :style="{ width: '50px', height: '50px', fill: color }"
      />
    </template>
    <template v-else-if="['fontawesome', 'devicons'].includes(skill.chosenLibrary)">
      <i
        :class="[skill.icons[skill.chosenLibrary], size.iconSize, 'mb-1']"
        :style="{ color: skill.color }"
        aria-hidden="true"
      ></i>
    </template>
    <template v-else-if="skill.chosenLibrary === 'material'">
      <span
        :class="['material-icons', size.iconSize, 'mb-1']"
        :style="{ color: skill.color }"
        aria-hidden="true"
      >
        {{ skill.icons[skill.chosenLibrary] }}
      </span>
    </template>
    <template v-else>
      <i
        :class="['fas fa-question', size.iconSize, 'mb-1']"
        :style="{ color: skill.color }"
        aria-hidden="true"
      ></i>
    </template>

    <h4 :class="['text-center text-gray-800 leading-tight', size.textSize]">
      {{ skill.title }}
    </h4>
  </div>
  <div v-else class="bg-card-background p-2 rounded-lg shadow-md flex items-center justify-center">
    <span class="text-red-500">Compétence non trouvée</span>
  </div>
</template>

<script src="./SkillIcon.js"></script>

<style scoped>
.icon-svg {
  width: 50px;
  height: 50px;
}
</style>
