// src/components/EducationHistory/EducationHistory.js

import educationData from '@/data/education.json';
import EducationModal from '@/components/EducationModal/EducationModal.vue';
import gsap from 'gsap';

export default {
  name: 'EducationHistory',
  components: {
    EducationModal,
  },
  data() {
    return {
      education: educationData.education,
      certificates: educationData.certificates,
      selectedItem: null,
      selectedCertificate: null,
      modalType: null,
      failedImages: [], // Suivi des images échouées
    };
  },
  methods: {
    openModal(item, type) {
      if (type === 'education') {
        this.selectedItem = item;
      } else if (type === 'certificate') {
        this.selectedCertificate = item;
      }
      this.modalType = type;
    },
    closeModal() {
      this.selectedItem = null;
      this.selectedCertificate = null;
      this.modalType = null;
    },
    handleImageError(imageUrl) {
      if (!this.failedImages.includes(imageUrl)) {
        this.failedImages.push(imageUrl);
      }
    },
    isImageFailed(imageUrl) {
      return this.failedImages.includes(imageUrl);
    },
    onEnter(el) {
      // Animation d'entrée avec GSAP
      gsap.fromTo(
        el,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.5, ease: 'power2.out' }
      );
    },
    onLeave(el, done) {
      // Animation de sortie avec GSAP
      gsap.to(el, {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: 'power2.in',
        onComplete: done,
      });
    },
  },
};
