// src/components/EducationModal/EducationModal.js

export default {
  name: 'EducationModal',
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      failedImages: [], // Suivi des images échouées
    };
  },
  methods: {
    toggleModuleDetails(module) {
      module.showDetails = !module.showDetails;
    },
    handleImageError(imageUrl) {
      if (!this.failedImages.includes(imageUrl)) {
        this.failedImages.push(imageUrl);
      }
    },
    isImageFailed(imageUrl) {
      return this.failedImages.includes(imageUrl);
    },
  },
};