// src/components/ProjectsOverview/ProjectsOverview.js
import ProjectCard from '@/components/ProjectCard/ProjectCard.vue';
import ProjectModal from '@/components/ProjectModal/ProjectModal.vue';
import projectsData from '@/data/projectsData.json';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'ProjectsOverview',
  components: {
    ProjectCard,
    ProjectModal,
  },
  data() {
    return {
      projects: projectsData.projects,
      selectedProject: null,
    };
  },
  mounted() {
    // Animer les cartes de projet avec un effet de révélation au scroll
    gsap.utils.toArray('.project-card').forEach((card) => {
      gsap.from(card, {
        scrollTrigger: {
          trigger: card,
          start: 'top 80%',
        },
        duration: 0.8,
        y: 50,
        opacity: 0,
        ease: 'power4.out',
      });
    });
  },
  methods: {
    openModal(project) {
      this.selectedProject = project;
    },
    closeModal() {
      this.selectedProject = null;
    },
  },
};
