<template>
  <div
    ref="card"
    class="bg-card-background rounded-lg shadow-md overflow-hidden cursor-pointer flex flex-col transform transition-transform duration-300 hover:scale-105"
    @click="handleClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <!-- Image du projet -->
    <div class="overflow-hidden">
      <img
        ref="cardImage"
        :src="project.image_url"
        :alt="project.projectName"
        class="w-full h-48 sm:h-52 md:h-56 lg:h-60 object-cover transition-transform duration-300"
        loading="lazy"
      />
    </div>

    <!-- Contenu de la carte -->
    <div class="p-4 sm:p-5 md:p-6 flex flex-col gap-2.5 flex-grow">
      <!-- Nom du projet -->
      <h3 class="text-lg sm:text-xl md:text-2xl text-secondary-color font-semibold">
        {{ project.projectName }}
      </h3>

      <!-- Description brève -->
      <p
        v-if="showDetail && project.briefDescription"
        class="text-sm sm:text-base md:text-lg text-text-color flex-grow"
      >
        {{ project.briefDescription }}
      </p>

      <!-- Durée du projet -->
      <p
        v-if="showDuree && project.date_debut && project.date_fin"
        class="text-xs sm:text-sm md:text-base text-text-color"
      >
        <strong>Durée :</strong>
        {{ formatDuration(project.date_debut, project.date_fin) }}
      </p>

      <!-- Lien vers le site web -->
      <div v-if="project.website" class="mt-2 sm:mt-4" @click.stop>
        <a
          :href="project.website"
          target="_blank"
          rel="noopener noreferrer"
          class="text-sm sm:text-base md:text-lg text-primary-color font-bold hover:text-hover-color hover:underline transition-colors duration-300 ease-in-out"
        >
          Visiter le site
        </a>
      </div>
    </div>
  </div>
</template>

<script src="./ProjectCard.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
