<!-- src/components/SkillDetailModal/SkillDetailModal.vue -->
<template>
  <div
    class="fixed top-0 bottom-0 right-0 left-0 md:left-[200px] bg-black bg-opacity-60 flex items-center justify-center z-50"
    @click.self="closeModal"
    role="dialog"
    aria-modal="true"
  >
    <div
      ref="modalContainer"
      class="relative bg-white rounded-lg p-6 max-w-lg w-full mx-4"
    >
      <!-- Bouton de fermeture avec ref -->
      <button
        ref="closeButton"
        class="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none text-base sm:text-lg"
        @click="closeModal"
        aria-label="Fermer la modale"
      >
        &times;
      </button>

      <!-- En-tête de la compétence -->
      <div class="flex flex-col items-center mb-4">
        <!-- Affichage de l'icône de la compétence -->
        <template
          v-if="['fontawesome', 'devicons', 'simpleIcons'].includes(skill.chosenLibrary)"
        >
          <i
            :class="skill.icons[skill.chosenLibrary]"
            :style="{ color: skill.color }"
            class="text-3xl sm:text-4xl mb-4"
            aria-hidden="true"
          ></i>
        </template>
        <template v-else-if="skill.chosenLibrary === 'material'">
          <span
            class="material-icons text-3xl sm:text-4xl mb-4"
            :style="{ color: skill.color }"
            aria-hidden="true"
          >
            {{ skill.icons[skill.chosenLibrary] }}
          </span>
        </template>
        <template v-else>
          <i
            class="fas fa-question text-3xl sm:text-4xl mb-4"
            :style="{ color: skill.color }"
            aria-hidden="true"
          ></i>
        </template>

        <h2 class="text-base sm:text-lg lg:text-lg font-bold">{{ skill.title }}</h2>
      </div>

      <!-- Description détaillée de la compétence -->
      <p class="text-sm sm:text-base lg:text-lg text-gray-700">{{ skill.detailedDescription }}</p>
    </div>
  </div>
</template>

<script src="./SkillDetailModal.js"></script>

<style scoped>
/* Aucun style supplémentaire nécessaire */
</style>
