<!-- src/components/AboutSection/ValueItem.vue -->
<template>
  <div ref="valueItem" class="flex items-start mb-5 transition-transform duration-300 ease-in-out hover:-translate-y-2 hover:shadow-lg w-full">
    <i :class="iconClass" class="text-blue-600 mr-4 mt-1.5 text-2xl"></i>
    <div>
      <h4 class="text-xl mb-1 text-gray-800">{{ value.title }}</h4>
      <p class="text-base text-gray-600 leading-6">{{ value.description }}</p>
    </div>
  </div>
</template>

<script>
// src/components/AboutSection/ValueItem.vue
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'ValueItem',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    iconClass() {
      switch (this.value.title) {
        case 'Innovation':
          return 'fas fa-lightbulb';
        case 'Accessibilité':
          return 'fas fa-universal-access';
        case 'Impact social':
          return 'fas fa-hand-holding-heart';
        case 'Collaboration':
          return 'fas fa-users';
        default:
          return 'fas fa-star';
      }
    },
  },
  mounted() {
    gsap.from(this.$refs.valueItem, {
      scrollTrigger: {
        trigger: this.$refs.valueItem,
        start: 'top 80%',
      },
      duration: 0.8,
      y: -50, // Animation descendante
      opacity: 0,
      ease: 'power2.out',
    });
  },
};

</script>

<style scoped>
/* Aucun style personnalisé nécessaire, Tailwind s'en charge */
</style>
