<!-- src/components/BlogDetails/BlogDetails.vue -->
<template>
  <div class="flex flex-col h-full ">
    
    <!-- Conteneur PDF -->
    <div
      ref="pdfContainer"
      class="pdf-container z-[15000] flex-grow mb-4 border border-gray-300 p-2 bg-card-background rounded-lg shadow-md"
    >
      <!-- Le PDF sera intégré ici par PDFObject -->
      <p class="text-center text-gray-500">Chargement du PDF...</p>
    </div>
    <!-- Lien de téléchargement PDF -->
    <a
      :href="blog.pdfPath"
      download
      class="inline-block mt-auto px-4 py-2 bg-primary text-white rounded-md font-bold hover:bg-hover transition-colors text-center"
    >
      Télécharger le PDF
    </a>
  </div>
</template>

<script>
import componentOptions from './BlogDetails.js'; // Importation du fichier JavaScript

export default {
  ...componentOptions,
  mounted() {
    componentOptions.mounted.call(this);
  },
};
</script>

<style scoped>
.pdf-container {
  width: 100%;
  height: calc(100% - 120px); /* Ajustez en fonction de la hauteur des en-têtes et pieds */
}

@media (max-width: 768px) {
  .pdf-container {
    height: 300px; /* Hauteur fixe pour les petits écrans */
  }
}
</style>
