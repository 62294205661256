// src/components/SkillDetailModal/SkillDetailModal.js
import { gsap } from 'gsap';

export default {
  name: 'SkillDetailModal',
  props: {
    skill: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeModal() {
      // Animer la disparition de la modale
      gsap.to(this.$refs.modalContainer, {
        duration: 0.5,
        opacity: 0,
        scale: 0.8,
        ease: 'power2.in',
        onComplete: () => {
          this.$emit('close-modal');
        },
      });
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);

    // Animer l'apparition de la modale
    gsap.from(this.$refs.modalContainer, {
      duration: 0.5,
      opacity: 0,
      scale: 0.8,
      ease: 'power2.out',
    });

    // Focus sur le bouton de fermeture lorsqu'elle est ouverte
    this.$nextTick(() => {
      if (this.$refs.closeButton) {
        this.$refs.closeButton.focus();
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
};
