<!-- src/App.vue -->
<template>
  <div id="app" class="flex flex-col min-h-screen bg-background font-roboto overflow-x-hidden">
    <!-- En-tête -->
    <header class="w-full h-header bg-gradient-to-r from-secondary to-primary text-white p-4 flex items-center justify-center fixed top-0 left-0 z-[15000] shadow-lg">
      <div class="text-center">
        <h1 class="text-2xl font-bold">Oumar Ben Lol</h1>
        <p class="text-sm">Ingénieur IA | Développeur Full Stack & Mobile | Data Scientist</p>
      </div>
    </header>

    <!-- Barre de navigation verticale pour les écrans md et supérieurs -->
    <nav class="hidden md:flex flex-col items-center fixed top-header left-0 bottom-0 w-nav bg-primary z-[15000] py-5 space-y-5 text-white">
      
      <ul class="space-y-4 w-full px-2">
        <li>
          <router-link 
            to="/" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-home mr-2"></i> Accueil
            <!-- Indicateur de lien actif -->
            <span v-if="$route.path === '/'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/about" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-user mr-2"></i> À propos
            <span v-if="$route.path === '/about'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/blogs" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-blog mr-2"></i> Blog
            <span v-if="$route.path === '/blogs'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/projects" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-project-diagram mr-2"></i> Projets
            <span v-if="$route.path === '/projects'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/skills" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-tools mr-2"></i> Compétences
            <span v-if="$route.path === '/skills'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/education" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-graduation-cap mr-2"></i> Formations
            <span v-if="$route.path === '/education'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/experience" 
            class="flex items-center px-4 py-2 text-lg text-white hover:bg-hover hover:text-white rounded transition relative"
            active-class="text-accent font-bold"
          >
            <i class="fas fa-briefcase mr-2"></i> Expérience
            <span v-if="$route.path === '/experience'" class="absolute left-2 top-1/2 transform -translate-y-1/2 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- Barre de navigation inférieure pour les écrans inférieurs à md -->
    <nav class="md:hidden fixed bottom-0 left-0 w-full h-bottom-nav bg-primary z-[15000] flex justify-around items-center text-white">
      <ul class="flex w-full justify-around items-center">
        <li>
          <router-link 
            to="/" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-home"></i>
            <span v-if="$route.path === '/'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/about" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-user"></i>
            <span v-if="$route.path === '/about'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/blogs" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-blog"></i>
            <span v-if="$route.path === '/blogs'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/projects" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-project-diagram"></i>
            <span v-if="$route.path === '/projects'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/skills" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-tools"></i>
            <span v-if="$route.path === '/skills'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/education" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-graduation-cap"></i>
            <span v-if="$route.path === '/education'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
        <li>
          <router-link 
            to="/experience" 
            class="text-white text-xl hover:text-accent transition relative"
            active-class="text-accent"
          >
            <i class="fas fa-briefcase"></i>
            <span v-if="$route.path === '/experience'" class="absolute top-0 right-0 w-2 h-2 bg-accent rounded-full"></span>
          </router-link>
        </li>
      </ul>
    </nav>

      <!-- Contenu principal -->
    <main class="mt-header md:ml-nav md:mt-header pb-[60px] md:pb-0 p-5 flex-grow">
      <router-view />
    </main>

    <!-- Pied de page -->
    <!-- Pied de page -->
<footer class="hidden md:block bg-primary text-white py-4 text-center relative z-40  md:pl-nav ">
  <div class="container mx-auto">
    <p>&copy; 2024 Oumar Ben Lol. Tous droits réservés.</p>
    <ul class="flex justify-center space-x-5 mt-4">
      <li>
        <a href="https://linkedin.com/in/oumar-ben-lol" target="_blank" rel="noopener noreferrer" class="text-2xl hover:text-accent transition">
          <i class="fab fa-linkedin text-white"></i>
        </a>
      </li>
      <li>
        <a href="https://github.com/oumar5" target="_blank" rel="noopener noreferrer" class="text-2xl hover:text-accent transition">
          <i class="fab fa-github text-white"></i>
        </a>
      </li>
      <li>
        <a href="mailto:oumarbenlol1@gmail.com" class="text-2xl hover:text-accent transition">
          <i class="fas fa-envelope text-white"></i>
        </a>
      </li>
    </ul>
  </div>
</footer>
  </div>
</template>

<script src="./App.js"></script>
<style >
/* src/App.css */

/* Importation des styles globaux */
@import './assets/css/global.css';

</style>